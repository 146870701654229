import { useState } from 'react';
import { graphql } from 'gatsby';
import { block } from 'bem-cn';
import loadable from '@loadable/component';

import Layout from 'layouts/';
import Heading from 'components/Heading';
import Title from 'components/Title';
import Container from 'components/Container';

const TimeLicense = loadable(() =>
	import('components/_pricing-page/timeLicense')
);
const ComparePackage = loadable(() =>
	import('components/_pricing-page/comparePackage')
);

import {
	faqItem,
	insideLink,
	faqBlock,
	renderAst,
} from 'components/markDownComponents';

const renderComToolkit = {
	faq: faqBlock,
	'faq-item': faqItem,
	'inside-link': insideLink,
};
const faqRenderCom = renderAst(renderComToolkit);

import './style.scss';
const cn = block('pricing-page');

const defaultSale = (timeout) => timeout.find((item) => item.default);

const PricingPage = ({ data }) => {
	const { seo, title, subtitle, timeout, packages } =
		data.markdownRemark.frontmatter;
	const { htmlAst } = data.markdownRemark;

	const [timePeriod, setTimePeriod] = useState(defaultSale(timeout));

	return (
		<Layout seo={seo}>
			<Container>
				<Heading
					weight='bold'
					align='center'
					color='general'
					className={cn('title')}
				>
					{title}
				</Heading>
				<Title color='secondary' level={6} align='center'>
					{subtitle}
				</Title>
				<div className={cn('compare-table', { container: true })}>
					<TimeLicense
						data={timeout}
						activeSale={timePeriod}
						onClick={setTimePeriod}
					/>
					<ul className={cn('compare-table', { wrap: true })}>
						{packages.map((pack) => (
							<ComparePackage
								{...pack}
								key={pack.title}
								timePeriod={timePeriod}
							/>
						))}
					</ul>
				</div>
				<div className={cn('content')}>{faqRenderCom(htmlAst)}</div>
			</Container>
		</Layout>
	);
};

export default PricingPage;

export const query = graphql`
	query getPricingPageData {
		markdownRemark(frontmatter: { slug: { eq: "/pricing" } }) {
			htmlAst
			frontmatter {
				title
				subtitle
				seo {
					description
					image
					link
					title
				}
				timeout {
					sale
					time
					default
				}
				packages {
					defaultPrice
					title
					withPrice
					eCommerceNumber
					sets {
						name
						price
						set {
							name
							price
							include
							default
							eCommerceNumber
						}
						include
					}
				}
			}
		}
	}
`;
